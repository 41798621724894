import { langFile } from "./helpers.js";

class HomeView {
  #html = document.querySelector("html");
  #navItems = document.querySelectorAll(".navitem");
  #languages = document.querySelector(".languages");
  #languageItems = document.querySelectorAll(".language-item");
  #sectionVideo = document.querySelector(".section-video");
  #sectionskyline = document.querySelector(".section-skyline");
  #sectionCompanyOverview = document.querySelector(".section-company-overview");
  #videoText = document.querySelector(".video-text");
  #scrollBtnOverview = document.querySelector(".scroll-button-overview");
  #scrollBtnSkyline = document.querySelector(".scroll-button-skyline");
  #currentLanguage = ["en"];
  #languageIds = document.querySelectorAll("[data-lang]");
  #inputName = document.querySelector(".input-name");
  #inputEmail = document.querySelector(".input-email");
  #listIconMobile = document.querySelector(".ph-list");
  #navMenu = document.querySelector(".navlist");

  mobileNav() {
    this.#listIconMobile.addEventListener("click", (e) => {
      this.#navMenu.classList.toggle("open");
    });
  }

  _translate(language) {
    this.#languageIds.forEach((text) => {
      // html header
      this.#html.dir = langFile[language].dir;
      this.#html.lang = langFile[language].lang;

      // move language bar on phone
      if (window.innerWidth < 763) {
        if (language === "he") {
          this.#languages.classList.add("rtl");
          this.#languages.classList.remove("ltr");
        }
        if (language !== "he") {
          this.#languages.classList.remove("rtl");
          this.#languages.classList.add("ltr");
        }
      }

      // document text
      text.textContent = langFile[language][text.dataset.lang];

      // update current language
      this.#currentLanguage[0] = language;

      // translate placeholders
      this.#inputName.placeholder = langFile[language].name;
      this.#inputEmail.placeholder = langFile[language].email;
    });
  }

  flipLanguage() {
    this.#languages.addEventListener("mouseover", (e) => {
      // reveal other lang
      this.#languageItems.forEach((item) => {
        item.addEventListener("click", (e) => {
          this._translate(e.target.id);
        });
        // change background color to highight
        item.style.backgroundColor = "#1a3d5c";
        if (item.classList.contains("hidden")) item.classList.remove("hidden");
      });
    });
    this.#languages.addEventListener("mouseout", (e) => {
      // return normal color
      this.#languageItems.forEach((item) => {
        item.style.backgroundColor = "#1a293c";
      });

      // find what current language is and hide the other
      this.#languageItems.forEach((item) => {
        if (item.id === this.#currentLanguage[0]) return;
        if (item.id !== this.#currentLanguage[0]) item.classList.add("hidden");
      });
    });
  }

  _scrollToSection(section, pos, time) {
    // const currentPos = window.scrollY;
    // let start = null;
    // if (time == null) time = 500;
    // (pos = +pos), (time = +time);
    // window.requestAnimationFrame(function step(currentTime) {
    //   start = !start ? currentTime : start;
    //   const progress = currentTime - start;

    //   if (currentPos < pos) {
    //     window.scrollTo(0, ((pos - currentPos) * progress) / time + currentPos);
    //   } else {
    //     window.scrollTo(0, currentPos - ((currentPos - pos) * progress) / time);
    //   }
    //   if (progress < time) {
    //     window.requestAnimationFrame(step);
    //   } else {
    //     window.scrollTo(0, pos);
    //   }
    // });

    section.scrollIntoView({
      behavior: "smooth",
      top: 0,
    });
  }

  _highlightNav(navId) {
    // remove highlighted nav
    this.#navItems.forEach((item) => {
      if (item.classList.contains("current-nav"))
        item.classList.remove("current-nav");
      // highlight relevant nav
      if (item.querySelector(".nav-link").dataset.id === navId)
        item.classList.add("current-nav");
    });
  }

  listenScrollDowns() {
    this.#scrollBtnOverview.addEventListener("click", () => {
      this._scrollToSection(this.#sectionskyline);
    });

    this.#scrollBtnSkyline.addEventListener("click", () => {
      this._scrollToSection(this.#sectionCompanyOverview);
    });

    this.#navItems.forEach((item) => {
      item.addEventListener("click", (e) => {
        const id = e.target.closest(".navitem").querySelector(".nav-link")
          .dataset.id;
        const section = document.querySelector(`#${id}`);
        this._scrollToSection(section);
        this._highlightNav(id);
        if (this.#navMenu.classList.contains("open"))
          this.#navMenu.classList.toggle("open");
      });
    });
  }

  toggleText(state) {
    if (state === "visible") {
      this.#videoText.classList.add("visible");
    }
    if (state !== "visible") {
      this.#videoText.classList.remove("visible");
    }
    this.#videoText.classList.toggle("visible");
  }

  handleIntersection(entries) {
    document.querySelector(".shadow-component").classList.toggle("visible");

    document.querySelector(".video-text").classList.toggle("visible");
    document
      .querySelector(".scroll-button-overview")
      .classList.toggle("visible");

    // document
    //   .querySelector(".video-block-component")
    //   .classList.toggle("shadow-component");
  }

  observeTopScroll() {
    const observer = new IntersectionObserver(this.handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.05,
    });

    observer.observe(this.#sectionskyline);
  }
}

export default new HomeView();
