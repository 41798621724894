export const langFile = {
  en: {
    dir: "ltr",
    lang: "en",
    name: "NAME",
    email: "EMAIL",
    docTitle: "Extell Development | Israel",
    description: "",
    overview: "Overview",
    skyline: "Extell Israel",
    company: "Contact",
    contactUs: "Contact Us",
    definition: "Defining the Standards of Real Estate Development",
    skylineText:
      "Extell Development Company has redefined the New York City skyline with an impressive portfolio of residential, office, hospitality, and mixed-use properties. These iconic properties have raised the benchmark and continue to set the standards of real estate development.",
    scrollDown: "Scroll Down",
    companyTextOne:
      "Extell is a full-service development company driven by an internal team of talented real estate professionals whose combined breadth of experience includes all areas of real estate development. Clear communication and proficient execution enable us to successfully acquire, finance, develop, market, and manage the most sophisticated development projects.",
    companyTextTwo:
      "The Extell success story includes developing some of the world’s most elevated residences and redefining luxury development throughout New York City. Our portfolio continues to expand throughout New York and other premier markets across the nation.",
    learnMore: "Learn More About EXTELL",
    register: "Register for Extell News Updates",
    subscribe: "SUBSCRIBE",
    copyright: "© 2024 Extell Development. All Rights Reserved",
  },
  he: {
    dir: "rtl",
    lang: "he",
    name: "שם",
    email: "מייל",
    docTitle: "אקסטל | ישראל",
    description: `
    היזם המוביל בנדל"ן בניו יורק, אקסטל דבלופמנט, יוצר נכסים שמגלמים את הייחודיות והחיות של מנהטן ומשתלבים באופן טבעי במרקם העיר.
    `,
    overview: "עמוד הבית",
    skyline: "אקסטל ישראל",
    company: "פרויקט טלביה-רחביה",
    contactUs: "פרויקט טלביה-רחביה",
    definition: `בעלות. התחדשות. מצוינות.`,
    skylineText: `חברת אקסטל עיצבה מחדש את קו הרקיע של ניו יורק עם פורטפוליו מרשים של נכסים למגורים, משרדים, אירוח ושימוש מעורב. הנכסים האייקוניים הללו העלו את הרף וממשיכים להגדיר את הסטנדרטים של פיתוח נדל"ן.`,
    scrollDown: "גלול למטה",
    companyTextOne: `
      אקסטל ישראל, חברה בת של קבוצת אקסטל, בבעלות יזם הנדל"ן היהודי-אמריקאי גארי ברנט, פועלת להובלת פרויקטים חדשניים בישראל, בדגש על איכות חיים, יציבות כלכלית ופיתוח בר-קיימא. עם ניסיון בינלאומי עשיר בפיתוח פרויקטים מורכבים, החברה מתמקדת בהענקת פתרונות איכותיים ומקיפים לשיפור הנכסים של לקוחותיה.
      `,
    companyTextTwo: `
      `,
    learnMore: "ראו להרחבה את האתר הבין לאומי של החברה",
    register: "הבטיחו את מקומכם בפרויקט הייחודי הזה וצרו עמנו קשר עוד היום!",
    subscribe: "הירשם",
    copyright: "© 2024 Extell Development. כל הזכויות שמורות",
  },
};
